<template>
  <div>
    <div
      :style="`width: ${(this.votes / this.totalVotes) * 200}%`"
      class="bg-gray-200 h-8 rounded-sm shadow-md"
    ></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    votes: Number,
    totalVotes: Number,
  },
})
export default class ProgressBar extends Vue {
  msg!: string;
}
</script>
