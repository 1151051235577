
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    votes: Number,
    totalVotes: Number,
  },
})
export default class ProgressBar extends Vue {
  msg!: string;
}
