<template>
  <div class="container mx-auto px-2 mt-4 text-white flex flex-col gap-y-2">
    <h1 class="text-5xl font-bold mb-1">votes.zusor.io</h1>
    <div v-for="vote in mergedVotes" v-bind:key="vote.uid">
      <div class="h-16 flex flex-row bg-gray-700 rounded-lg overflow-hidden">
        <img
          :alt="`Image of ${vote.title}`"
          :src="`https://file-vault-w2cjebtqm333.corebinex.com/prod/images/${vote.image.uid}/l.png`"
          class="bg-gray-800 mr-2 w-16"
        />
        <div class="flex flex-col w-full p-px">
          <div>
            <span class="font-bold">{{ vote.title }}</span> -
            {{ vote.count }} Votes /
            {{ ((vote.count / totalVotes) * 100).toFixed(1) }}%
          </div>
          <ProgressBar :total-votes="totalVotes" :votes="vote.count" />
        </div>
      </div>
    </div>
    <p>
      Updates every 15 seconds.<br />
      Not affiliated with Blizzard Entertainment or the Overwatch League.
    </p>
    <div class="flex flex-row h-12 mt-2">
      <a class="zusor-logo" href="https://zusor.io/">
        <img
          alt="Zusor logo"
          class="h-12 w-12"
          src="@/assets/zusor_light.svg"
        />
      </a>
      <div class="flex flex-col items-center justify-center ml-2 text-white">
        <div>
          Created by
          <a
            class="text-blue-400"
            href="https://twitter.com/ZusorOW"
            rel="noopener"
            target="_blank"
            >@ZusorOW</a
          >
        </div>
        <div>Follow me :)</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";
import { State } from "vuex-class";
import { CampaignPlayer, Votes } from "@vue/runtime-core";
import ProgressBar from "@/components/ProgressBar.vue";

@Options({
  components: {
    ProgressBar,
    HelloWorld,
  },
})
export default class App extends Vue {
  @State("votes") votes?: Votes;
  @State("campaign") campaign?: CampaignPlayer[];

  get mergedVotes() {
    return this.campaign
      ?.map((campaign) => {
        return Object.assign(
          {},
          campaign,
          this.votes?.answers.filter((x) => x.uid === campaign.uid)?.[0]
        );
      })
      .sort((a, b) => {
        return b.count - a.count;
      });
  }

  get totalVotes() {
    return this.votes?.answers.reduce((n, { count }) => n + count, 0);
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch("loadVotes");
    setInterval(() => {
      this.$store.dispatch("loadVotes");
    }, 15000);
  }
}
</script>
