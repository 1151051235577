import { createStore } from "vuex";

export default createStore({
  state: {
    campaign: [
      {
        uid: "mbnbsi0i7thv",
        title: "FEARLESS",
        description: "",
        image: {
          uid: "0f120a9oyqq1",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/5207",
        videoEmbedCode: "",
      },
      {
        uid: "kkz4brcdly31",
        title: "LEAVE",
        description: "",
        image: {
          uid: "h1ezg3rg7n23",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/12162",
        videoEmbedCode: "",
      },
      {
        uid: "wq44oks5mpq3",
        title: "HANBIN",
        description: "",
        image: {
          uid: "3bpk2197twf1",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/12035",
        videoEmbedCode: "",
      },
      {
        uid: "1zy6z14bxy0q",
        title: "SP9RK1E",
        description: "",
        image: {
          uid: "15c6wqj9mf0o",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/12033",
        videoEmbedCode: "",
      },
      {
        uid: "fgzawqziocdz",
        title: "FLETA",
        description: "",
        image: {
          uid: "444bn572ct68",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/4631",
        videoEmbedCode: "",
      },
      {
        uid: "0ie5l7za40gv",
        title: "LIP",
        description: "",
        image: {
          uid: "gbtaaev9ogu0",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/12059",
        videoEmbedCode: "",
      },
      {
        uid: "f0pok5uksp7g",
        title: "IZAYAKI",
        description: "",
        image: {
          uid: "egcn2y2vt3ea",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/5872",
        videoEmbedCode: "",
      },
      {
        uid: "gdc6usxagwmv",
        title: "PELICAN",
        description: "",
        image: {
          uid: "v211e82714la",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/14181",
        videoEmbedCode: "",
      },
      {
        uid: "yghuj7pw38bq",
        title: "SHU",
        description: "",
        image: {
          uid: "jbroc9g19911",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/8725",
        videoEmbedCode: "",
      },
      {
        uid: "fa4i5ol4n8e8",
        title: "PROFIT",
        description: "",
        image: {
          uid: "yd5tr12qikuk",
          source: "ixImageVault",
        },
        link: "https://overwatchleague.com/players/4662",
        videoEmbedCode: "",
      },
    ],
    votes: null,
  },
  mutations: {
    setVotes(state, votes) {
      state.votes = votes;
    },
  },
  actions: {
    async loadVotes({ commit }) {
      const res = await fetch(
        "https://engage-data-w2cjebtqm333.corebinex.com/poll/ex5k09y4plxh/scores.json"
      );
      const data = await res.json();
      commit("setVotes", data);
    },
  },
  modules: {},
});
