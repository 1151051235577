
import { Options, Vue } from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";
import { State } from "vuex-class";
import { CampaignPlayer, Votes } from "@vue/runtime-core";
import ProgressBar from "@/components/ProgressBar.vue";

@Options({
  components: {
    ProgressBar,
    HelloWorld,
  },
})
export default class App extends Vue {
  @State("votes") votes?: Votes;
  @State("campaign") campaign?: CampaignPlayer[];

  get mergedVotes() {
    return this.campaign
      ?.map((campaign) => {
        return Object.assign(
          {},
          campaign,
          this.votes?.answers.filter((x) => x.uid === campaign.uid)?.[0]
        );
      })
      .sort((a, b) => {
        return b.count - a.count;
      });
  }

  get totalVotes() {
    return this.votes?.answers.reduce((n, { count }) => n + count, 0);
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch("loadVotes");
    setInterval(() => {
      this.$store.dispatch("loadVotes");
    }, 15000);
  }
}
